<template>
  <div class="ProjectcaseDetail">
    <div class="caseDetail_left">
    <img :src="ProjectcaseDetail.example">
    </div>
    <div class="caseDetail_right">
      <div class="caseDetail_title">{{ ProjectcaseDetail.name }}</div>
      <div class="caseDetail_img">
        <img :src="checked ? ProjectcaseDetail.qrDev : ProjectcaseDetail.qrFormer" >
      </div>
      <div class="caseDetail_text" v-if="checked">{{ ProjectcaseDetail.textDev }}</div>
      <div class="caseDetail_text" v-else>{{ ProjectcaseDetail.textFormer }}</div>
      <div class="buttons">
        <div :class="['caseDetail_button1', !checked ? 'button1' : '']" @click="channelBtnFn1" > {{ ProjectcaseDetail.btnFormer }}  </div>
        <div :class="['caseDetail_button1', checked ? 'button1' : '']" @click="channelBtnFn2">{{ ProjectcaseDetail.btnDev}} </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        checked: true,
        ProjectcaseDetail: {},
      }
    },
    props: ['case'],
    created() {
      this.ProjectcaseDetail = this.case;
    },
    watch: {
      case: function() {
        this.ProjectcaseDetail = this.case;
      }
    },
    methods: {
      channelBtnFn1() {
        this.checked = false;
      },
      channelBtnFn2() {
        this.checked = true;
      },
    },
  }
</script>
<style>
  .ProjectcaseDetail{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    margin: 20px auto;
    box-sizing: border-box;
  }
  .caseDetail_left{
    background-color: white;
    width: 150px;
    height: 260px;
  }
  .caseDetail_left img{
    width: 100%;
    height: 100%;
  }
  .caseDetail_right{
    background-color: white !important;
    width: 280px;
    height: 320px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  .caseDetail_title{
    font-weight: bold;
  }
  .caseDetail_img{
    width: 100px;
    height: 100px;
    /* margin: 20px auto; */
  }
  .caseDetail_img img{
    width: 100%;
    height: 100%;
  }
  .buttons{
    min-width:190px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .caseDetail_button1{
    width: 90px;
    height: 30px;
    color: white;
    background-color: rgb(0, 123, 211);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  .button1{
    background-color: orange;
    transition: background-color 0.5s ease-in-out;
  }
</style>
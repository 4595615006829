<template>
  <div class="ProjectcaseDetail1"> 
    <div class="caseDetail_left">
      <img :src="projectcaseDetail.example">
    </div>
    <div class="caseDetail_right">
      <div class="caseDetail_title"> {{projectcaseDetail.name}} </div>
      <div class="caseDetail_img">
        <img :src="projectcaseDetail.qr">
      </div>
      <div class="caseDetail_button" @click="channelBtnFn()"> {{projectcaseDetail.btn}}</div>
    </div>
  </div>
</template>
<script>
  // interface Subcase {
  //   name: string,
  //   type: string,
  // }

  // interface SubcaseNormal extends Subcase {
  //   example: string,
  //   qr: string,
  //   btn: string,
  //   link: string,
  // }

  // interface SubcaseWechat extends Subcase {
  //   example: string,
  //   qrFormer: string,
  //   qrDev: string,
  //   textDev: string,
  //   textFormer: string,
  //   btnDev: string,
  //   btnFormer: string,
  //   link: string,
  // }
  // {
  //   checked: boolean,
  //   projectcaseDetail: SubcaseNormal,
  // } 

  export default {
    props: ['case'],
    data() {
      return {
        checked: true,
        projectcaseDetail: {
          name: '测试',
          type: '测试',
          example: '/',
          qr: '/',
          btn: '测试',
          link: '/',
        },
      };
    },
    created: function() {
      this.projectcaseDetail = this.case;
    },
    methods: {
      channelBtnFn: function() {
        this.checked = !this.checked;
        if (this.projectcaseDetail.link !== "") {
          window.open(this.projectcaseDetail.link, '_blank');
        } else {
          this.$message({
            type:"warning",
            message:"暂无网页"
          })
        }
      }
    },
    watch: {
    case: function() {
      this.projectcaseDetail = this.case;
    },
    },
  }
</script>
<style>
  .ProjectcaseDetail1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width:  100%;
    margin: 20px auto;
    box-sizing: border-box;
    margin: 20px auto 40px;
  }
  .caseDetail_left{
    background-color: white;
    width: 150px;
    height: 280px;
  }
  .caseDetail_left img{
    width: 100%;
    height: 100%;
  }
  .caseDetail_right{
    background-color: white !important;
    width: 280px;
    height: 340px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  .caseDetail_title{
    font-weight: bold;
  }
  .caseDetail_img{
    width: 100px;
    height: 100px;
  }
  .caseDetail_img img{
    width: 100%;
    height: 100%;
  }
  .caseDetail_button{
    width: 150px;
    height: 30px;
    color: white;
    background-color: rgb(0, 123, 211);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
</style>
<template>
  <div class="case">
    <div class="case_title">
      <div class="case_left">项目案例</div>
      <div class="case_right">
        <select class="select" v-model="index">
          <option class="option" v-for="(val, key) in cases" :key="key" :value="key">{{ val.title }}</option>
        </select>
      </div>
    </div>
    <div class="case_engCase">CASES</div>
    <div class="case_img">
      <img src="../assets/images/downnarrow.png">
    </div>
    <div class="case_contents">
      <div class="case_content" v-for="(val, key) in cases[index].subcases" :key="key">
        <!-- <ProjectcaseDetail v-if="val.type == '后台' || '桌面端' || '大屏' || '公众号'" :case="val" /> -->
        <ProjectcaseDetail v-if="val.type == '后台'" :case="val" />
        <ProjectcaseDetail v-if="val.type == '后台体验版'" :case="val" />
        <ProjectcaseDetail v-if="val.type == '桌面端'" :case="val" />
        <ProjectcaseDetail v-if="val.type == '大屏'" :case="val" />
        <ProjectcaseDetail v-if="val.type == '公众号'" :case="val" />
        <ProjectcaseApplet v-if="val.type == '小程序'" :case="val"/>
      </div>
    </div>
  </div>
</template>
<script>
  import cases from '../utils/caseData';
  import ProjectcaseApplet from './component/ProjectcaseApplet.vue';
  import ProjectcaseDetail from './component/ProjectcaseDetail.vue';

  // interface Subcase {
  //   name: string,
  //   type: string,
  // }

  // interface SubcaseNormal extends Subcase {
  //   example: string,
  //   qr: string,
  //   btn: string,
  //   link: string,
  // }

  // interface SubcaseWechat extends Subcase {
  //   example: string,
  //   qrFormer: string,
  //   qrDev: string,
  //   textDev: string,
  //   textFormer: string,
  //   btnDev: string,
  //   btnFormer: string,
  //   link: string,
  // }

  export default {
    data() {
      return {
        index: 0,
        cases: cases,
      }
    },
    components: {
      ProjectcaseDetail,
      ProjectcaseApplet,
    },
  }
</script>
<style>
.case{
  background-color: rgb(243, 243, 243);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.case_title{
  width: 30%;
  margin: 0 auto;
  display: flex;
  font-size: 24px;
  margin: 20px auto;
}
.case_left{
  width: 50%;
  height: 40px;
  background-color: rgb(0, 123, 211);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.case_right{
  width: 50%;
  height: 40px;
}
.select{
  width: 100%;
  height: 100%;
  font-size: 18px;
}
.case_img{
  width: 40px;
  height: 40px;
  margin: 20px auto;
}
.case_img img{
  height: 100%;
  width: 100%;
}
.case_contents{
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.case_content{
  width: 45%;
  /* display: flex;
  border: 1px solid;
  justify-content: space-around;
  align-items: center; */
}
.option{
  height: 40px;
}
</style>
<template>
  <div id="app">
    <TopTitle/>
    <BanNer/>
    <AboutUs/>
    <ProjectCase/>
    <TechAdvtanage/>
    <FootTer/>
  </div>
</template>

<script>

import TopTitle from './components/TopTitle.vue';
import BanNer from "./components/BanNer.vue";
import AboutUs from "./components/AboutUS.vue";
import ProjectCase from "./components/ProjectCase.vue";
import TechAdvtanage from './components/TechAdvtanage.vue';
import FootTer from './components/FootTer.vue'


export default {
  name: 'App',
  components: {
    TopTitle,
    BanNer,
    AboutUs,
    ProjectCase,
    TechAdvtanage,
    FootTer
  },
  data(){
    return{
    }
  }
}
</script>

<style>
#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div class="Top">
    <div class="Top_left">江苏虞悦信息科技有限公司</div>
    <div class="Top_right">
      <a href="http://"> <span>首页</span> </a>
      <a href="http://"> <span>关于我们</span> </a>
      <a href="http://"> <span>项目案例</span> </a>
      <a href="http://"> <span>技术优势</span> </a>
      <a href="http://"> <span>新闻动态</span> </a>
      <a href="http://"> <span>联系我们</span> </a>
    </div>
  </div>
</template>
<script>
  export default{

  }
</script>
<style>
.Top{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  white-space: nowrap;
}
.Top_left{
  font-size:18px;
  font-weight: bold;
  margin-right: 200px;
}
.Top_right{
  display: flex;
  align-items: center;
  height: 80px;
}
a{
  color: #333;
}
.Top_right span{
  margin: 20px 30px;
}
</style>
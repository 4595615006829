<template>
  <div class="all">
    <div class="us_title">关于我们</div>
    <div class="engTitle">ABOUT US</div>
    <div class="img">
      <img src="../assets/images/downnarrow.png">
    </div>
    <div class="Us_text">
      江苏虞悦信息科技有限公司成立以来，公司以强大的核心技术为最大优势， 加之专业的服务团队，目前已与国内外众多知名企业建立了长期合作关系。 具有创新理念、先进管理水平的新兴科技企业。 公司拥有雄厚的科技力量和强大的科研实力，团队成员包括毕业于哈佛、UCLA、 北大等海内外知名院校的高学历、高素质人才，我们一直秉承专业、诚信、进取的服务核心，旨在通过自己不懈的努力，为企业连接一切商机， 帮助客户在互联网时代和环境中保持优势，与客户风雨同行、共同成长、分享喜悦、做技术营销的领跑者
    </div>
    <div class="more">了解更多</div>
  </div>
</template>
<script>
  export default{
  }

</script>
<style>
  .all{
    margin: 200px auto 0;
    width: 80%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .us_title{
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
  }
  .img{
    width: 40px;
    height: 40px;
  }
  img{
    width: 100%;
    height: 100%;
  }
  .Us_text{
    width: 64%;
  }
  .more{
    width: 180px;
    height: 40px;
    color: white;
    background-color: rgb(0, 123, 211);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    font-size: 14px;
  }
</style>
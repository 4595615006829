export default  [
  {
    title: '广告机',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/shaoyet.jpg',
        qr: '/assets/images/case/broswer/dev/qr/shaoyet.jpg',
        btn: '进入网站',
        link: 'https://client.guangj.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/shaoyet.jpg',
        qr: '/assets/images/case/broswer/dev/qr/shaoyet.jpg',
        btn: '进入网站',
        link: 'https://test.client.guangj.kimnxcx.com',
      },
    ],
  },
  {
    title: '邵阳儿童',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/shaoyet.jpg',
        qr: '/assets/images/case/broswer/qr/shaoyet.png',
        btn: '进入网站',
        link: 'https://client.shaoyet.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/shaoyet.jpg',
        qr: '/assets/images/case/broswer/dev/qr/shaoyet.jpg',
        btn: '进入网站',
        link: 'https://test.client.shaoyet.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/shaoyet.jpg',
        qrFormer:'/assets/images/case/jumin/former/shaoyet.jpg',
        qrDev: '/assets/images/case/jumin/development/shaoyet.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '智慧畜牧',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/zhihxm.png',
        qr: '/assets/images/case/broswer/dev/qr/zhihxm.png',
        btn: '进入网站',
        link: 'https://client.zhihxm.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/zhihxm.png',
        qr: '/assets/images/case/broswer/dev/qr/zhihxm.png',
        btn: '进入网站',
        link: 'https://test.client.zhihxm.kimnxcx.com',
      },
      {
        name: '兴牧防',
        type: '小程序',
        example: '/assets/images/case/jumin/gaoyet.jpg',
        qrFormer: '/assets/images/case/jumin/former/gaoyet.jpg',
        qrDev: '/assets/images/case/jumin/development/zhihxmXingmf.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '高邮儿童',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/gaoyet.jpg',
        qr: '/assets/images/case/broswer/dev/qr/gaoyet.png',
        btn: '进入网站',
        link: 'https://client.gaoyet.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/gaoyet.jpg',
        qr: '/assets/images/case/broswer/dev/qr/gaoyet.png',
        btn: '进入网站',
        link: 'https://test.client.gaoyet.kimnxcx.com',
      },
      {
        name: '高邮儿童',
        type: '小程序',
        example: '/assets/images/case/jumin/gaoyet.jpg',
        qrFormer: '/assets/images/case/jumin/former/gaoyet.jpg',
        qrDev: '/assets/images/case/jumin/development/gaoyet.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '戴南统计',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/daintj.png',
        qr: '/assets/images/case/broswer/dev/qr/daintj.png',
        btn: '进入网站',
        link: 'https://test.client.daintj.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/daintj.png',
        qr: '/assets/images/case/broswer/dev/qr/daintj.png',
        btn: '进入网站',
        link: 'https://test.client.daintj.kimnxcx.com',
      },
    ],
  },
  {
    title: '板桥英才',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/dev/banqyc.png',
        qr: '/assets/images/case/broswer/qr/banqyc.png',
        btn: '进入网站',
        link: 'https://client.banqyc.kimnxcx.com/',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/banqyc.png',
        qr: '/assets/images/case/broswer/dev/qr/banqyc.png',
        btn: '进入网站',
        link: 'https://test.client.banqyc.kimnxcx.com/',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/banqyc.jpg',
        qrFormer: '/assets/images/case/jumin/former/banqyc.jpg',
        qrDev: '/assets/images/case/jumin/development/banqyc.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '兴化网格',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/xinghwg.png',
        qr: '/assets/images/case/broswer/qr/xinghwg.png',
        btn: '进入网站',
        link: 'https://client.xinghwg.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/xinghwg.png',
        qr: '/assets/images/case/broswer/dev/qr/zzzw.png',
        btn: '进入网站',
        link: 'https://test.client.xinghwg.kimnxcx.com/',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xinghwg.jpg',
        qrFormer: '/assets/images/case/jumin/former/xinghwg.jpg',
        qrDev: '/assets/images/case/jumin/development/xinghwg.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '周庄政务',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/zzzw.png',
        qr: '/assets/images/case/broswer/qr/zzzw.png',
        btn: '进入网站',
        link: 'https://client.xinghzw.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/dev/zzzw.png',
        qr: '/assets/images/case/broswer/dev/qr/zzzw.png',
        btn: '进入网站',
        link: 'https://test.client.xinghzw.kimnxcx.com/',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/zzzw.png',
        qrFormer: '/assets/images/case/jumin/former/zzzw.jpg',
        qrDev: '/assets/images/case/jumin/development/zzzw.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '盐城保障金',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/yancbzj.png',
        qr: '/assets/images/case/broswer/qr/yancbzj.png',
        btn: '进入网站',
        link: 'https://client.yancbzj.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/ycbzj.jpg',
        qrFormer: '/assets/images/case/jumin/former/yancbzj.jpg',
        qrDev: '/assets/images/case/jumin/development/yancbzj.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '同心助力',
    subcases: [
      {
        name: '后台体验版',
        type: '后台',
        example: 'assets/images/case/broswer/xinghtx.png',
        qr: '/assets/images/case/broswer/qr/xinghtx.png',
        btn: '进入网站',
        link: 'https://test.client.xinghtx.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xinghtx.jpg',
        qrFormer: '/assets/images/case/jumin/development/xinghtx.jpg',
        qrDev: '/assets/images/case/jumin/development/xinghtx.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '昌荣农业',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/changrny.png',
        qr: '/assets/images/case/broswer/qr/changrny.png',
        btn: '进入网站',
        link: 'https://client.changrny.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/changrny.jpg',
        qrFormer: '/assets/images/case/jumin/former/changrny.jpg',
        qrDev: '/assets/images/case/jumin/development/changrny.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '行政中心',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/xingzzx.png',
        qr: '/assets/images/case/broswer/qr/xingzzx.png',
        btn: '进入网站',
        link: 'https://client.xingzzx.kimnxcx.com',
      },
      {
        name: '公众号',
        type: '公众号',
        example: 'assets/images/case/broswer/xingzzx.png',
        qr: '/assets/images/case/broswer/qr/xingzzx.png',
        btn: '进入网站',
        link: 'https://client.xingzzx.kimnxcx.com',
      },
    ],
  },
  
  {
    title: '宿迁儿童',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/suqet.png',
        qr: '/assets/images/case/broswer/qr/suqet.png',
        btn: '进入网站',
        link: 'https://sqbfjz.mzj.suqian.gov.cn:8443/index',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/suqet.png',
        qr: '/assets/images/case/broswer/dev/qr/suqet.png',
        btn: '进入网站',
        link: 'https://test.client.suqet.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/suqet.jpg',
        qrFormer: '/assets/images/case/jumin/former/suqet.jpg',
        qrDev: '/assets/images/case/jumin/development/suqet.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '我的兴化',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: 'assets/images/case/broswer/myxh.jpg',
        qr: '/assets/images/case/broswer/qr/myxh-browser.png',
        btn: '进入网站',
        link: 'https://client.myxh.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: 'assets/images/case/broswer/myxh.jpg',
        qr: '/assets/images/case/broswer/dev/qr/myxh.png',
        btn: '进入网站',
        link: 'https://test.client.myxh.kimnxcx.com',
      },
      {
        name: '公众号',
        type: '公众号',
        example: '/assets/images/case/publicAccount/myxh.jpg',
        qr: '/assets/images/case/publicAccount/qr/myxh-public.png',
        btn: '公众号',
        link: 'https://client.myxh.kimnxcx.com',
      },
      {
        name: '大屏',
        type: '大屏',
        example: '/assets/images/case/echarts/myxh.jpg',
        qr: 'assets/images/case/echarts/qr/myxh.png',
        btn: '大屏',
        link: 'https://client.myxh.kimnxcx.com',
      },
      {
        name:'居民端',
        type: '小程序',
        example: '/assets/images/case/jumin/myxh.jpg',
        qrFormer: '/assets/images/case/jumin/former/myxh-cityzen-former.jpg',
        qrDev: '/assets/images/case/jumin/development/myxh-cityzen-trier.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
      {
        name:'政务端',
        type: '小程序',
        example: '/assets/images/case/jumin/myxh.jpg',
        qrFormer: '/assets/images/case/zhengwu/former/myxh-official-former.jpg',
        qrDev: '/assets/images/case/zhengwu/development/myxh-official-trier.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '我的泰兴',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/wdtx.jpg',
        qr: '/assets/images/case/broswer/qr/wdtx-browser.png',
        btn: '进入网站',
        link: 'https://client.taixwd.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/wdtx.jpg',
        qr: '/assets/images/case/broswer/dev/qr/taixwd.png',
        btn: '进入网站',
        link: 'https://test.client.taixwd.kimnxcx.com',
      },
      {
        name:'居民端',
        type: '小程序',
        example: '/assets/images/case/jumin/mytx.jpg',
        qrFormer: '/assets/images/case/jumin/former/wdtx-former.jpg',
        qrDev: '/assets/images/case/jumin/development/wdtx.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
      {
        name:'政务端',
        type: '小程序',
        example: '/assets/images/case/zhengwu/wdtx.jpg',
        qrFormer: '/assets/images/case/zhengwu/former/wdtx.jpg',
        qrDev: '/assets/images/case/zhengwu/development/wdtx.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '兴化未保',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/xhwb.jpg',
        qr: '/assets/images/case/broswer/qr/xhwb.png',
        btn: '进入网站',
        link: 'https://client.guanaet.kimnxcx.com',
      },
      
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/xhwb.jpg',
        qr: '/assets/images/case/broswer/dev/qr/xhwb.png',
        btn: '进入网站',
        link: 'https://test.client.guanaet.kimnxcx.com',
      },
      {
        name: '公众号',
        type: '公众号',
        example: '/assets/images/case/publicAccount/xhwb.jpg',
        qr: '/assets/images/case/publicAccount/qr/xhwb.jpg',
        btn: '公众号',
        link: 'https://client.guanaet.kimnxcx.com',
      },
      {
        name: '大屏',
        type: '大屏',
        example: '/assets/images/case/echarts/xhwb.jpg',
        qr: '/assets/images/case/echarts/qr/xhwb.png',
        btn: '大屏',
        link: 'https://client.guanaet.kimnxcx.com',
      },
      {
        name:'小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xhwb.jpg',
        qrFormer: '/assets/images/case/jumin/former/xhwb.jpg',
        qrDev: '/assets/images/case/jumin/development/xhwb.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '宿城未保',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/scwb.jpg',
        qr: '/assets/images/case/broswer/qr/scwb.png',
        btn: '进入网站',
        link: 'https://client.sucet.kimnxcx.com',
      },
      
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/scwb.jpg',
        qr: '/assets/images/case/broswer/qr/scwb.png',
        btn: '进入网站',
        link: 'https://test.client.sucet.kimnxcx.com',
      },
      {
        name:'小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/scwb.jpg',
        qrFormer: '/assets/images/case/jumin/former/scwb.jpg',
        qrDev: '/assets/images/case/jumin/development/scwb.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '兴化精患',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/xhjh.jpg',
        qr: '/assets/images/case/broswer/qr/xhjh.png',
        btn: '进入网站',
        link: 'https://client.tesrq.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/xhjh.jpg',
        qr: '/assets/images/case/broswer/qr/xhjh.png',
        btn: '进入网站',
        link: 'https://test.client.tesrq.kimnxcx.com',
      },
      {
        name:'小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xhjh.jpg',
        qrFormer: '/assets/images/case/jumin/former/xhjh.jpg',
        qrDev: '/assets/images/case/jumin/development/xhjh.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '兴化养老',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/xhyl.jpg',
        qr: '/assets/images/case/broswer/qr/xhyl.jpg',
        btn: '进入网站',
        link: 'https://client.yangl.kimnxcx.com/',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/xhyl.jpg',
        qr: '/assets/images/case/broswer/qr/xhyl.jpg',
        btn: '进入网站',
        link: 'https://test.client.yangl.kimnxcx.com',
      },
      {
        name: '桌面端',
        type: '桌面端',
        example: '/assets/images/case/publicAccount/xhyl.jpg',
        qr: '/assets/images/case/publicAccount/qr/xhyl.jpg',
        btn: '公众号',
        link: 'https://test.client.yangl.kimnxcx.com',
      },
      {
        name: '助餐小程序',
        type: '小程序',
        example: 'assets/images/case/zhengwu/xhyl.jpg',
        qrFormer: '/assets/images/case/zhengwu/former/xhyl.jpg',
        qrDev: '/assets/images/case/zhengwu/development/xhyl.jpg',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
      {
        name: '服务小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xhyl.jpg',
        qrFormer: '/assets/images/case/jumin/former/xhyl.jpg',
        qrDev: '/assets/images/case/jumin/development/xhyl.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '兴化残联',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/xinghcl.png',
        qr: '/assets/images/case/broswer/qr/xinghcl.png',
        btn: '进入网站',
        link: 'https://client.xinghcl.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/xinghcl.png',
        qr: '/assets/images/case/broswer/qr/xinghcl.png',
        btn: '进入网站',
        link: 'https://test.client.xinghcl.kimnxcx.com',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xinghcl.png',
        qrFormer: '/assets/images/case/jumin/former/xinghcl.jpg',
        qrDev: '/assets/images/case/jumin/development/xinghcl.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '大垛党建',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/dddj.jpg',
        qr: '/assets/images/case/broswer/qr/dddj.png',
        btn: '进入网站',
        link: 'https://client.daddj.kimnxcx.com',
      },
      {
        name: '后台体验版',
        type: '后台体验版',
        example: '/assets/images/case/broswer/dddj.jpg',
        qr: '/assets/images/case/broswer/qr/dddj.png',
        btn: '进入网站',
        link: 'https://test.client.daddj.kimnxcx.com',
      },
      {
        name: '居民端',
        type: '小程序',
        example: '/assets/images/case/jumin/dddj.jpg',
        qrFormer: '/assets/images/case/jumin/former/dddj.jpg',
        qrDev: '/assets/images/case/jumin/development/dddj.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
      {
        name: '政务端',
        type: '小程序',
        example: '/assets/images/case/zhengwu/dddj.jpg',
        qrFormer: '/assets/images/case/zhengwu/former/dddj.jpg',
        qrDev: '/assets/images/case/zhengwu/development/dddj.jpg',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '综合大屏',
    subcases: [
      {
        name: '大屏',
        type: '大屏',
        example: '/assets/images/case/echarts/zhdp.jpg',
        qr: '/assets/images/case/echarts/qr/zhdp.png',
        btn: '进入网站',
        link: '',
      },
    ],
  },
  {
    title: '纪念馆',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/jng.jpg',
        qr: '/assets/images/case/broswer/qr/jng.png',
        btn: '进入网站',
        link: 'https://jng.kimnxcx.com',
      },
      {
        name: '体验版',
        type: '小程序',
        example: '/assets/images/case/jumin/jng.jpg',
        qrFormer: '/assets/images/case/jumin/development/jng.png',
        qrDev: '/assets/images/case/jumin/development/jng.png',
        textDev: '体验版',
        textFormer: '体验版',
        btnDev: '体验版',
        btnFormer: '体验版',
        link: '',
      },
    ],
  },
  {
    title: '兽医站',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/syz.jpg',
        qr: '/assets/images/case/broswer/qr/syz.png',
        btn: '进入网站',
        link: 'https://medicine.kimnxcx.com/',
      },
    ],
  },   
  {
    title: '信访局',
    subcases: [
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/xfj.jpg',
        qrFormer: '/assets/images/case/jumin/former/xfj.jpg',
        qrDev: '/assets/images/case/jumin/development/xfj.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
  {
    title: '宗教局',
    subcases: [
      {
        name: '后台正式版',
        type: '后台',
        example: '/assets/images/case/broswer/zjj.jpg',
        qr: '/assets/images/case/broswer/qr/zjj.png',
        btn: '进入网站',
        link: 'https://yanc.kimnxcx.com/',
      },
      {
        name: '小程序',
        type: '小程序',
        example: '/assets/images/case/jumin/zjj.jpg',
        qrFormer: '/assets/images/case/jumin/former/zjj.jpg',
        qrDev: '/assets/images/case/jumin/development/zjj.png',
        textDev: '体验版',
        textFormer: '正式版',
        btnDev: '体验版',
        btnFormer: '正式版',
        link: '',
      },
    ],
  },
]
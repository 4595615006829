<template>
  <div class="nav">
    <div class="top">
      <img src="../assets/images/bj.jpg" style="width: 100%;">
    </div>
    <div class="banner">
      <div class="detail" v-for="(item, index) in detailDate" :key="index">
        <div class="nav_img">
          <img :src="item.src">
        </div>
        <div class="title bold">{{item.title}}</div>
        <div class="texts">{{item.text}}</div>
        <div class="content">{{item.content}}</div>
        <div class="border" v-if="index < detailDate.length - 1"></div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    data() {
      return {
        detailDate: [
          {
            src: "./assets/images/nav/t1.png",
            title: '优秀的技术团队',
            text: '优秀的技术开发团队',
            content: '专注于产品的研发和测试',
          },
          {
            src: './assets/images/nav/t2.png',
            title :'专业的技术支持',
            text: '专业的技术支持',
            content: '专注于产品的研发和测试',
          },
          {
            src: './assets/images/nav/t3.png',
            title: '一流的技术服务',
            text: '一流的技术服务',
            content: '专注于产品的研发和测试',
          },
          {
            src: './assets/images/nav/t4.png',
            title: '强大的核心技术',
            text: '整套的核心技术体系',
            content: '完善产品的应用体系',
          },
        ]
      }
    }
  }
</script>
<style>
  .nav{
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
  }
  .banner{
    position: absolute;
    top: 85%;
    left: 0;
    right: 0;
    width: 70%;
    display: flex;
    background-color: white;
    margin: 0 auto;
    justify-content: center;
    white-space: nowrap;
  }
  .detail{
    width: 24%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .border{
    position: absolute;
    right: 0;
    top: 40%;
    border-right:1px solid rgb(212, 208, 208);
    height: 60px;
  }
  .texts{
    width: 100%;
  }
  .nav_img{
    width:  80px;
    height: 80px;
    border-radius: 40px;
    margin: 20px 0 0px;
    background-color: rgb(77, 180, 255);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .nav_img img{
    width: 80%;
    height: 80%;
  }
  .title{
    font-weight: bold;
    margin: 20px 0;
  }
</style>
<template>
   <div class="tech">
      <div class="tech_title">技术优势</div>
      <div class="tech_engText">TECHNOLOGY</div>
      <div class="tech_img">
        <img src="../assets/images/downnarrow.png">
      </div>
      <div class="tech_content">
        <div class="content_detail" v-for="(item, index) in details" :key="index">
          <div class="content_img" >
            <img :src="item.src" >
          </div>
          <div class="content_text">{{item.text}}</div>
        </div>
      </div>
   </div>
</template>
<script>
  export default{
    data() {
      return{
        details: {
          detail1: {
            src: './assets/images/tech/1.png',
            text: '主流前端框架'
          },
          detail2: {
            src: './assets/images/tech/2.png',
            text: '全平台互动'
          },
          detail3: {
            src: './assets/images/tech/3.png',
            text: '个性化滚动条'
          },
          detail4: {
            src: './assets/images/tech/4.png',
            text: '智能统计'
          },
          detail12: {
            src: './assets/images/tech/5.png',
            text: '自动文件系统'
          },
          detail5: {
            src: './assets/images/tech/6.png',
            text: '多维视图'
          },
          detail6: {
            src: './assets/images/tech/7.png',
            text: '实时信息'
          },
          detail7: {
            src: './assets/images/tech/8.png',
            text: '多语言支持'
          },
          detail8: {
            src: './assets/images/tech/9.png',
            text: '高可靠代码'
          },
          detail9: {
            src: './assets/images/tech/10.png',
            text: '系统定制'
          },
          detail10: {
            src: './assets/images/tech/11.png',
            text: '7*24监控'
          },
          detail11: {
            src: './assets/images/tech/12.png',
            text: '多浏览器支持'
          },
        }
      }
    }
  }
</script>
<style>
  .tech{
    min-width: 70%;
    max-width: 70%;
    margin: 0 auto;
  }
  .tech_title{
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
  }
  .tech_engText{
    margin: 20px auto;
  }
  .tech_img{
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .tech_img img{
    width: 100%;
    height: 100%;
  }
  .tech_content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
  }
  .content_detail{
    height: 200px;
    width: 200px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction:column ;
    border: 1px solid rgb(215, 214, 214);
    text-align: center;
  }
  .content_img{
  }
  .content_img img{
    height: 100%;
    width: 100%;
  }
  .content_text{
    text-align: center;
  }
</style>
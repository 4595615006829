<template>
  <div class="foot">
    <div class="foot_title">江苏虞悦信息科技有限公司</div>
    <div class="foot_content" v-for="(item, index) in details" :key="index">
      <div class="footContent_left">
        <div class="left_img">
          <img :src="item.src">
        </div>
        <div class="footContent_title">{{item.title}} </div>
      </div>
      <div class="footContent_text">
        {{item.text}}
      </div>
    </div>
    <div class="foot_version">
      <div class="version_left">
        @版权所有
        <span>江苏虞悦信息科技有限公司</span>
      </div>
      <div class="borde"></div>
      <a href="https://beian.miit.gov.cn/#/Integrated/index">
        <div class="version_right"> 苏ICP备18042801号-2</div>
      </a>
    </div>
  </div>
</template>
<script lang="ts">
  // interface Detail {
  //   src: string,
  //   title: string,
  //   text: string,
  // }
  // interface Details {
  //   detail1: Detail,
  //   detail2: Detail,
  //   detail3: Detail,
  // }
  export default{
    data() {
      let details = {
          detail1: {
            src: './assets/images/foot/dianhua.png',
            title: '手机：',
            text: '15996019996',
          },
          detail2: {
            src: './assets/images/foot/youxiang.png',
            title: '邮箱：',
            text: ' UesugiKaneshin@outlook.com',
          },
          detail3: {
            src: './assets/images/foot/location.png',
            title: '地址：',
            text: '江苏省南京市建邺区嘉陵江东街18号04幢2层209-9室',
          },
      }
      return {details: details};
    }
  }
</script>
<style>
  .foot{
    width: 100%;
    background-color: rgb(21, 26, 47);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 15%;
    box-sizing: border-box;
    align-items: flex-start;
    margin-top: 10px;
  }
  .foot_title{
    font-size: 24px;
    margin: 20px 0;
    color: #ccc;
  }
  .foot_content{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    color: #999;
    align-items: center;
    margin: 5px 0;
  }
  .footContent_left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
  }
  .left_img{
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
  .left_img img{
    width: 100%;
    height: 100%;
  }
  .content_text{
    width: 64%;
  }
  .foot_version{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
  }
  .version_left{
    font-size: 12px;
  }
  .version_left span{
    font-size: 14px;
    margin-right: 20px;
  }
  .borde{
    border-left: 1px solid #999;
    height: 12px;
    margin-right: 20px;
  }
  .version_right{
    font-size: 14px;
    color: #999;
  }
  a{
    text-decoration: none;
  }
</style>